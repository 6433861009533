import React from "react";
import "tailwindcss/tailwind.css";
import { RouterProvider } from "react-router-dom";
import CustomRouter from "./AppRouter";

function App() {
  return (
    <>
      <RouterProvider router={CustomRouter} />
    </>
  );
}

export default App;
