import React from "react";

export const SignupSuccess = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center bg-p3_black w-[100%] h-[640px]">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="45"
            viewBox="0 0 150 45"
            fill="none"
          >
            <path
              d="M141.959 33.3855C142.461 33.3855 142.908 33.2119 143.299 32.8646C143.746 32.4827 143.969 31.9445 143.969 31.25V10.6251H150V36.4063H143.969V35.1563C142.573 36.0938 141.121 36.5625 139.614 36.5625H139.362C137.296 36.5625 135.9 36.1285 135.174 35.2605C134.504 34.3577 134.169 33.125 134.169 31.5625V10.6251H140.284V31.4584C140.284 32.7431 140.786 33.3855 141.791 33.3855H141.959Z"
              fill="white"
            />
            <path
              d="M123.622 36.5625C121.556 36.5625 119.852 36.1285 118.512 35.2605C117.172 34.3577 116.502 33.125 116.502 31.5625V15.4688C116.558 13.9063 117.228 12.691 118.512 11.823C119.852 10.9202 121.556 10.4688 123.622 10.4688H125.046C127.112 10.4688 128.815 10.9202 130.155 11.823C131.551 12.691 132.249 13.9063 132.249 15.4688V31.5625C132.249 33.125 131.551 34.3577 130.155 35.2605C128.815 36.1285 127.112 36.5625 125.046 36.5625H123.622ZM124.292 13.6459C123.175 13.6459 122.617 14.2883 122.617 15.573V31.4584C122.617 32.7431 123.175 33.3855 124.292 33.3855H124.627C125.632 33.3855 126.135 32.7431 126.135 31.4584V15.573C126.135 14.2883 125.632 13.6459 124.627 13.6459H124.292Z"
              fill="white"
            />
            <path
              d="M110.605 10.6251H115.547L110.186 45H104.155L105.83 36.4063L99.5479 10.6251H105.076L107.84 25.9375H108.092L110.605 10.6251Z"
              fill="white"
            />
            <path
              d="M83.0342 22.9687L90.154 36.4062H84.0394L79.3487 27.3437V36.4062H73.3177V0H79.3487V19.1146L84.2907 10.625H90.154L83.0342 22.9687Z"
              fill="white"
            />
            <path
              d="M63.1955 13.6459C62.7488 13.6459 62.33 13.8195 61.9391 14.1667C61.5482 14.4792 61.3248 14.9306 61.269 15.5209V36.4063H55.1543V10.6251H61.269V11.8751C61.9391 11.4584 62.6371 11.1285 63.363 10.8855C64.089 10.6077 64.8428 10.4688 65.6246 10.4688H65.7921C67.8583 10.4688 69.2264 10.9202 69.8965 11.823C70.6225 12.691 70.9854 13.9063 70.9854 15.4688V36.4063H64.9545V15.573C64.9545 14.2883 64.424 13.6459 63.363 13.6459H63.1955Z"
              fill="white"
            />
            <path
              d="M42.1782 36.5625C40.112 36.5625 38.7718 36.1285 38.1576 35.2605C37.5992 34.3577 37.3199 33.125 37.3199 31.5625V26.9792C37.3199 25.4167 37.6829 24.2014 38.4089 23.3334C39.1348 22.4306 40.5029 21.9792 42.5132 21.9792H42.7645C43.4346 21.9792 44.1326 22.0834 44.8586 22.2917C45.5845 22.4653 46.2825 22.691 46.9526 22.9688V15.573C46.9526 14.2883 46.4221 13.6459 45.3612 13.6459H45.0261C43.9651 13.6459 43.4346 14.2883 43.4346 15.573V21.2501H37.3199V15.4688C37.3199 13.9063 37.99 12.691 39.3302 11.823C40.7263 10.9202 42.4574 10.4688 44.5235 10.4688H45.9475C48.0136 10.4688 49.7168 10.9202 51.057 11.823C52.3972 12.691 53.0673 13.9237 53.0673 15.5209V36.4063H46.9526V35.4688C45.3332 36.198 43.8255 36.5625 42.4295 36.5625H42.1782ZM45.0261 24.7917C43.9651 24.7917 43.4346 25.4341 43.4346 26.7188V31.823C43.4346 33.1077 43.9651 33.75 45.0261 33.75H45.1936C45.6962 33.75 46.115 33.6112 46.4501 33.3334C46.7851 33.0209 46.9526 32.5174 46.9526 31.823V25.7292C46.9526 25.382 46.7851 25.1389 46.4501 25.0001C46.1709 24.8612 45.8916 24.7917 45.6124 24.7917H45.0261Z"
              fill="white"
            />
            <path
              d="M27.4534 13.6458C27.0067 13.6458 26.5879 13.8194 26.197 14.1667C25.8061 14.4792 25.5827 14.9305 25.5269 15.5208V36.4062H19.4122V0H25.5269V11.875C26.1412 11.4583 26.8113 11.1285 27.5372 10.8854C28.2631 10.6076 29.017 10.4687 29.7988 10.4687H30.0501C32.1162 10.4687 33.4843 10.9201 34.1544 11.8229C34.8804 12.691 35.2434 13.9062 35.2434 15.4687V36.4062H29.1287V15.5729C29.1287 14.2882 28.6261 13.6458 27.621 13.6458H27.4534Z"
              fill="white"
            />
            <path
              d="M17.8414 3.4375H12.2293V36.4062H5.69586V3.4375H0V0H17.8414V3.4375Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex flex-col justify-center items-center my-6">
          <p className="text-p3_white font-roboto text-[16px]">
            We are still working in production
          </p>
          <p className="text-p3_white font-roboto text-[16px]">
            to make a great cross-cultural community.
          </p>
          <p className="text-p3_white font-roboto text-[16px]">
            You can DM us for anything anytime,
          </p>
          <p className="text-p3_white font-roboto text-[16px]">
            We’ll write you back in 5 mins!
          </p>
          <p className="text-p3_white font-roboto text-[16px]">
            We’ll also let you know first
          </p>
          <p className="text-p3_white font-roboto text-[16px]">
            when the service is ready!
          </p>
          <a href="https://www.instagram.com/thro_official">
            <button className="w-[173px] h-[49px] rounded-full py-3 px-13 gap-10 bg-p3_pink mt-14">
              <p className="font-roboto font-extrabold text-[18px] text-p3_white">
                @thro_official
              </p>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};
