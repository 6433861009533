import React from "react";
import { Logo } from "./logo/Logo";
// import { MypageProfile } from "./profile/MypageProfile";

export const HeaderBar = () => {
  return (
    <div className="flex bg-p3_black p-5 w-[100%]">
      <Logo />
      {/* <div className="flex-grow"></div> */}
      {/* <MypageProfile /> */}
    </div>
  );
};
