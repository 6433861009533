import React from "react";
import { useNavigate } from "react-router-dom";

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <button
      className="text-p3_white text-2xl font-roboto font-extrabold w-[41.3px]"
      onClick={() => navigate("/")}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41.3"
        height="21"
        viewBox="0 0 140 70"
        fill="none"
      >
        <path
          d="M122.905 70C121.175 70 119.445 69.8031 117.715 69.4093C115.985 69.0155 114.408 68.4248 112.984 67.6371C111.661 66.8495 110.592 65.8322 109.778 64.5851C108.964 63.338 108.557 61.8284 108.557 60.0563V9.94374C108.557 8.17159 108.964 6.66198 109.778 5.41491C110.592 4.16784 111.661 3.15049 112.984 2.36287C114.408 1.57525 115.985 0.98453 117.715 0.590716C119.445 0.196905 121.175 0 122.905 0H125.652C127.382 0 129.112 0.196905 130.842 0.590716C132.572 0.98453 134.098 1.57525 135.421 2.36287C136.846 3.15049 137.965 4.16784 138.779 5.41491C139.593 6.66198 140 8.17159 140 9.94374V60.0563C140 61.8284 139.593 63.338 138.779 64.5851C137.965 65.8322 136.846 66.8495 135.421 67.6371C134.098 68.4248 132.572 69.0155 130.842 69.4093C129.112 69.8031 127.382 70 125.652 70H122.905ZM123.973 6.49789C121.633 6.49789 120.463 8.04032 120.463 11.1252V58.8748C120.463 60.5813 120.768 61.7956 121.378 62.5176C121.989 63.1739 122.854 63.5021 123.973 63.5021H124.584C125.703 63.5021 126.568 63.1739 127.179 62.5176C127.789 61.7956 128.094 60.5813 128.094 58.8748V11.1252C128.094 8.04032 126.924 6.49789 124.584 6.49789H123.973Z"
          fill="white"
        />
        <path
          d="M103.041 28.3544C103.041 30.4547 102.532 32.03 101.514 33.0802C100.497 34.0647 99.2249 34.721 97.6986 35.0492C99.2249 35.3118 100.497 35.9681 101.514 37.0183C102.532 38.0028 103.041 39.5452 103.041 41.6456V57.1027C103.041 59.3343 103.092 61.2049 103.193 62.7145C103.397 64.2241 103.6 65.4712 103.804 66.4557C104.008 67.3746 104.211 68.0637 104.415 68.5232C104.618 68.9826 104.771 69.278 104.872 69.4093H92.8142C92.1019 68.6217 91.5932 67.2761 91.2879 65.3727C91.0844 63.4036 90.9826 60.647 90.9826 57.1027V42.3347C90.9826 39.3811 89.965 37.9043 87.9299 37.9043H83.3508V69.4093H71.4453V0.590698H88.6931C92.6616 0.590698 96.0196 1.44396 98.767 3.15048C101.616 4.85699 103.041 7.2855 103.041 10.436V28.3544ZM87.9299 32.1941C89.965 32.1941 90.9826 30.7501 90.9826 27.8622V11.6174C90.9826 8.5982 89.8124 7.08859 87.472 7.08859H83.3508V32.1941H87.9299Z"
          fill="white"
        />
        <path
          d="M66.5109 0.590698V69.4093H54.6053V37.9043H47.2788V69.4093H35.3733V0.590698H47.2788V32.1941H54.6053V0.590698H66.5109Z"
          fill="white"
        />
        <path
          d="M32.5113 7.08859H22.2848V69.4093H10.3792V7.08859H0V0.590698H32.5113V7.08859Z"
          fill="white"
        />
      </svg>
    </button>
  );
};
