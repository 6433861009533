import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderBar } from "./headerBar/index";

export const Layout = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className="w-full max-w-[500px] mx-auto">
        <div className="w-full">
          <HeaderBar />
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
