import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const Main = () => {
  const navigate = useNavigate();
  const [uuid, setUuid] = useState<string>();

  useEffect(() => {
    let uuid = localStorage.getItem("uuid");
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }
    setUuid(uuid);
  }, []);

  const topBtnHandler = () => {
    const fetch = async () => {
      try {
        const res = await axios.post("https://violetpay.net/promo/collect2", {
          session_id: uuid,
          project: "thro",
          event: "top-button-click",
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    navigate("/login");
  };
  const bottomBtnHandler = () => {
    const fetch = async () => {
      try {
        const res = await axios.post("https://violetpay.net/promo/collect2", {
          session_id: uuid,
          project: "thro",
          event: "bottom-button-click",
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    navigate("/login");
  };
  return (
    <div className="relative flex flex-col justify-center items-center bg-p3_black w-[100%]">
      <img
        src="graTop.svg"
        alt="gra_svg"
        className="absolute inset-0 object-cover"
      />
      <div className="flex flex-col justify-center items-center mt-36 w-[232px] h-[68px] z-10">
        <p className="text-p3_white font-roboto text-[24px]">
          Connect with koreans
        </p>
        <p className="text-p3_white font-roboto text-[24px]">Through</p>
      </div>
      {/* THRO Logo */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="140"
        height="70"
        viewBox="0 0 140 70"
        fill="none"
        className="mt-10 z-10"
      >
        <path
          d="M122.905 70C121.175 70 119.445 69.8031 117.715 69.4093C115.985 69.0155 114.408 68.4248 112.984 67.6371C111.661 66.8495 110.592 65.8322 109.778 64.5851C108.964 63.338 108.557 61.8284 108.557 60.0563V9.94374C108.557 8.17159 108.964 6.66198 109.778 5.41491C110.592 4.16784 111.661 3.15049 112.984 2.36287C114.408 1.57525 115.985 0.98453 117.715 0.590716C119.445 0.196905 121.175 0 122.905 0H125.652C127.382 0 129.112 0.196905 130.842 0.590716C132.572 0.98453 134.098 1.57525 135.421 2.36287C136.846 3.15049 137.965 4.16784 138.779 5.41491C139.593 6.66198 140 8.17159 140 9.94374V60.0563C140 61.8284 139.593 63.338 138.779 64.5851C137.965 65.8322 136.846 66.8495 135.421 67.6371C134.098 68.4248 132.572 69.0155 130.842 69.4093C129.112 69.8031 127.382 70 125.652 70H122.905ZM123.973 6.49789C121.633 6.49789 120.463 8.04032 120.463 11.1252V58.8748C120.463 60.5813 120.768 61.7956 121.378 62.5176C121.989 63.1739 122.854 63.5021 123.973 63.5021H124.584C125.703 63.5021 126.568 63.1739 127.179 62.5176C127.789 61.7956 128.094 60.5813 128.094 58.8748V11.1252C128.094 8.04032 126.924 6.49789 124.584 6.49789H123.973Z"
          fill="white"
        />
        <path
          d="M103.041 28.3544C103.041 30.4547 102.532 32.03 101.514 33.0802C100.497 34.0647 99.2249 34.721 97.6986 35.0492C99.2249 35.3118 100.497 35.9681 101.514 37.0183C102.532 38.0028 103.041 39.5452 103.041 41.6456V57.1027C103.041 59.3343 103.092 61.2049 103.193 62.7145C103.397 64.2241 103.6 65.4712 103.804 66.4557C104.008 67.3746 104.211 68.0637 104.415 68.5232C104.618 68.9826 104.771 69.278 104.872 69.4093H92.8142C92.1019 68.6217 91.5932 67.2761 91.2879 65.3727C91.0844 63.4036 90.9826 60.647 90.9826 57.1027V42.3347C90.9826 39.3811 89.965 37.9043 87.9299 37.9043H83.3508V69.4093H71.4453V0.590698H88.6931C92.6616 0.590698 96.0196 1.44396 98.767 3.15048C101.616 4.85699 103.041 7.2855 103.041 10.436V28.3544ZM87.9299 32.1941C89.965 32.1941 90.9826 30.7501 90.9826 27.8622V11.6174C90.9826 8.5982 89.8124 7.08859 87.472 7.08859H83.3508V32.1941H87.9299Z"
          fill="white"
        />
        <path
          d="M66.5109 0.590698V69.4093H54.6053V37.9043H47.2788V69.4093H35.3733V0.590698H47.2788V32.1941H54.6053V0.590698H66.5109Z"
          fill="white"
        />
        <path
          d="M32.5113 7.08859H22.2848V69.4093H10.3792V7.08859H0V0.590698H32.5113V7.08859Z"
          fill="white"
        />
      </svg>
      <button
        className="w-[125px] rounded-full bg-[#030303] my-16 py-3 px-13 z-10"
        onClick={topBtnHandler}
      >
        <p className="text-p3_white font-roboto font-extrabold text-[18px]">
          Join
        </p>
      </button>
      <div className="mb-24 z-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="14"
          viewBox="0 0 30 18"
          fill="none"
        >
          <path
            d="M2 2L14.3 15.6634C14.3898 15.7696 14.4982 15.8543 14.6186 15.9122C14.739 15.9701 14.8688 16 15 16C15.1312 16 15.261 15.9701 15.3814 15.9122C15.5018 15.8543 15.6102 15.7696 15.7 15.6634L28 2"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="flex flex-col w-[250px]">
        <h3 className="w-[70px] font-roboto text-[36px] font-extrabold text-p3_pink">
          Find
        </h3>
        <div className="w-[212px] text-p3_white font-roboto text-[16px]">
          <p>Five friends at most.</p>
          <p>we say ‘no’ to short,</p>
          <p>superficial friendships</p>
          <p>We value quality over quantity</p>
        </div>
      </div>
      <div className="mt-10 mb-28">
        <img src="/find.png" alt="findImg" />
      </div>
      <div className="flex flex-col w-[250px]">
        <h3 className="w-[182px] font-roboto text-[36px] font-extrabold text-p3_pink">
          Like Native
        </h3>
        <div className="text-p3_white font-roboto text-[16px]">
          <p>Chat seamlessly.</p>
          <p>Every words you write</p>
          <p>will be auto translated.</p>
          <p>slangs and memes all</p>
          <p>across the border.</p>
        </div>
      </div>
      <div className="mt-10 mb-28">
        <img src="/Like_native.png" alt="findImg" />
      </div>
      <div className="flex flex-col w-[250px]">
        <h3 className="w-[165px] font-roboto text-[36px] font-extrabold text-p3_pink">
          Life Share
        </h3>
        <div className="text-p3_white font-roboto text-[16px]">
          <p>Exchange schedule and calendar.</p>
          <p>Live the same moment,</p>
          <p>in the same timeline</p>
        </div>
      </div>
      <div className="mt-10 mb-28">
        <img src="/life_share.png" alt="findImg" />
      </div>
      <div className="flex flex-col w-[250px]">
        <h3 className="w-[165px] font-roboto text-[36px] font-extrabold text-p3_pink">
          Video Call
        </h3>
        <div className="text-p3_white font-roboto text-[16px]">
          <p>Live auto translation</p>
          <p>experience like no other.</p>
          <p>As if you are native,</p>
          <p>No lagging in communication.</p>
        </div>
      </div>
      <div className="mt-10 mb-28">
        <img src="/video_call.png" alt="findImg" />
      </div>
      <div className="flex flex-col w-[250px]">
        <h3 className="w-[165px] font-roboto text-[36px] font-extrabold text-p3_pink">
          Gift
        </h3>
        <div className="text-p3_white font-roboto text-[16px]">
          <p>No shipping fee.</p>
          <p>All the gifts across the border.</p>
          <p>Simple and powerfull.</p>
          <p>Also cheap!</p>
        </div>
      </div>
      <div className="mt-10 mb-28">
        <img src="/gift.png" alt="findImg" />
      </div>
      <img
        src="graBottom.svg"
        alt="gra_svg"
        className="absolute bottom-0 object-cover"
      />
      <div className="flex flex-col items-center mb-36 z-10">
        <p className="text-p3_white font-roboto text-[24px] font-semibold">
          Join us and
        </p>
        <p className="text-p3_white font-roboto text-[24px] font-semibold">
          become one of
        </p>
        <p className="text-p3_white font-roboto text-[24px] font-semibold">
          our cross-cultural
        </p>
        <p className="text-p3_white font-roboto text-[24px] font-semibold">
          community
        </p>
      </div>
      <button
        onClick={bottomBtnHandler}
        className="absolute bottom-0 w-[321px] bg-p3_pink rounded-full font-roboto text-p3_white my-5 z-11"
      >
        <p className="text-[18px] font-extrabold py-3 px-13">Start Thro!</p>
      </button>
    </div>
  );
};
