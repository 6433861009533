import React from "react";
import "tailwindcss/tailwind.css";
import { createBrowserRouter } from "react-router-dom";
import { Layout } from "./layout/Layout";
import { Main } from "@pages/Main";
import { Login } from "@pages/Login";
import { SignupSuccess } from "@pages/Signup";

const CustomRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <span>404</span>,
    children: [
      {
        path: "",
        element: <Main />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup_success",
        element: <SignupSuccess />,
      },
    ],
  },
]);

export default CustomRouter;
